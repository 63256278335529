// import { AiFillStar } from "react-icons/ai";

// const Review = ({ rating, user, review, heading }) => {
//   return (
//     <div className="flex flex-col gap-2 border ">
//       <div className="flex gap-2">
//         {new Array(rating).fill("").map((_, i) => (
//           <AiFillStar key={i} className={"text-blue-500 h-5 w-5"} />
//         ))}
//         {!!(5 - rating) &&
//           new Array(5 - rating)
//             .fill("")
//             .map((_, i) => (
//               <AiFillStar className="text-slate-400 h-5 w-5" key={i} />
//             ))}
//       </div>
//       {
//         <p className="text-ellipsis overflow-hidden whitespace-nowrap font-semibold text-lg">
//           {user || "Anonymous"}
//         </p>
//       }
//       {review && { review }}
//     </div>
//   );
// };

// export default Review;

import { AiFillStar } from "react-icons/ai";

const Review = ({ rating, user, review, heading }) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-1">
        {new Array(rating).fill("").map((_, i) => (
          <AiFillStar key={i} className="text-blue-500 h-5 w-5" />
        ))}
        {!!(5 - rating) &&
          new Array(5 - rating)
            .fill("")
            .map((_, i) => (
              <AiFillStar className="text-slate-400 h-5 w-5" key={i} />
            ))}
      </div>
      <p className="text-ellipsis overflow-hidden whitespace-nowrap font-semibold text-lg">
        {user || "Anonymous"}
      </p>
      {review && <p className="text-sm">{review}</p>}
    </div>
  );
};

export default Review;
