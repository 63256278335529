import React from "react";

export default function SentPage() {
  return (
    <div className="mt-[10vh] w-[90%] md:w-[80%] m-[auto]">
      <div
        className={`text-[30px] md:text-[40px] flex justify-center items-center my-4`}
      >
        Your resume has been sent on your email !
      </div>
    </div>
  );
}
