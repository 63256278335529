import React, { useState } from "react";
import Button from "../../InputControl/Button";
import ProjectBlock from "./blocks/ProjectBlock";

export default function ProjectDetails(props) {
  const [data, setData] = useState([]);
  const [check, setCheck] = useState(true);
  const [saved, setSaved] = useState(false);
  const [addMorePrompt, setAddMorePrompt] = useState(false); // State to show prompt
  const [proceedWithoutMore, setProceedWithoutMore] = useState(false); // State to allow proceeding

  const handleSubmit = () => {
    if (saved) {
      if (data.length === 1 && !proceedWithoutMore) {
        setAddMorePrompt(true); // Show prompt if only one project and not confirmed
        setProceedWithoutMore(true); // Set to allow proceeding on next click
      } else {
        props.setData(data);
        props.setcomp(6);
        setCheck(true);
        setAddMorePrompt(false);
        setProceedWithoutMore(false); // Reset for future submissions
      }
    } else {
      setCheck(false);
    }
  };

  const handleBack = () => {
    props.setcomp(4);
  };

  const [array, setArray] = useState([
    <ProjectBlock
      setcomp={props.setcomp}
      save={setSaved}
      data={data}
      set={setData}
    />,
  ]);
  const addBlock = () => {
    setArray([
      ...array,
      <ProjectBlock
        setcomp={props.setcomp}
        save={setSaved}
        data={data}
        set={setData}
      />,
    ]);
    setSaved(false);
    setAddMorePrompt(false); // Reset the prompt state if a new project is added
    setProceedWithoutMore(false); // Prevent proceeding directly after adding
  };

  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flex flex-col items-center justify-center text-[30px] md:mb-4">
        Project Details
        <div className="text-[12px]">Hit Save!</div>
      </div>
      {array.map((ele, i) => {
        return (
          <div key={i}>
            {ele}
            <div className="w-full h-[1px] bg-slate-600 my-2"></div>
          </div>
        );
      })}
      <div className="w-full flex justify-center my-2">
        <div
          className={`text-[20px] ${saved ? "block" : "hidden"}`}
          onClick={addBlock}
        >
          <Button text="Add Project" />
        </div>
      </div>
      {addMorePrompt && (
        <div className="text-yellow-500 text-[12px] text-center my-2">
          Add more projects for better results or Click Next!
        </div>
      )}
      <div className="w-full flex justify-between gap-x-8">
        <div className="flex gap-x-8 w-[100px]" onClick={handleBack}>
          <Button text="Back" />
        </div>
        <div className="flex flex-col ml-auto gap-x-8" onClick={handleSubmit}>
          <Button text="Next" />
          <div
            className={`text-red-500 ${
              !check ? "block" : "hidden"
            } text-[12px]`}
          >
            Something is wrong
          </div>
        </div>
      </div>
    </div>
  );
}
